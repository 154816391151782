import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import Heading from 'components/heading/heading';
import { classNameWithModifiers } from 'helpers/utilities/utilities';
export default function Highlights(props) {
    if (!props.highlights) {
        return null;
    }
    return (_jsx(Heading, { size: props.headingSize, className: classNameWithModifiers('component-highlights', props.modifierClasses), children: props.highlights.map((highlight) => (_jsx("span", { className: "component-highlights__line", children: _jsx("span", { className: classNameWithModifiers('component-highlights__highlight', props.modifierClasses), children: highlight }) }))) }));
} // ----- Default Props ----- //
Highlights.defaultProps = {
    modifierClasses: [],
};
