import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import Heading from 'components/heading/heading';
import { classNameWithModifiers } from 'helpers/utilities/utilities';
import './text.scss';
// ----- Render ----- //
function Text({ title, children, headingSize, className, }) {
    return (_jsxs("div", { className: [
            className,
            classNameWithModifiers('component-text', [
                !children ? 'heading-only' : null,
            ]),
        ].join(' '), children: [title && _jsx(Title, { size: headingSize, children: title }), children] }));
}
Text.defaultProps = {
    headingSize: 2,
    children: null,
    title: null,
    className: null,
};
// ----- Children ----- //
export function Title({ children, size, }) {
    return (_jsx(Heading, { size: size, className: "component-text__heading", children: children }));
}
export function LargeParagraph({ children, }) {
    return _jsx("p", { className: "component-text__large", children: children });
}
export function SansParagraph({ children, }) {
    return _jsx("p", { className: "component-text__sans", children: children });
}
// ----- Exports ----- //
export default Text;
