// ----- Imports ----- //
import * as ophan from 'ophan';
import { testIsActive } from 'helpers/abTests/abtest';
import { getLocal, setLocal } from 'helpers/storage/storage';
// ----- Functions ----- //
const trackComponentEvents = (componentEvent) => ophan.record({
    componentEvent,
});
export const buildOphanPayload = (participations) => {
    const activeTests = Object.entries(participations).filter(testIsActive);
    return activeTests.reduce((payload, participation) => {
        const ophanABEvent = {
            variantName: participation[1],
            complete: false,
            campaignCodes: [],
        };
        return Object.assign({}, payload, {
            [participation[0]]: ophanABEvent,
        });
    }, {});
};
const trackAbTests = (participations) => ophan.record({
    abTestRegister: buildOphanPayload(participations),
});
// Set referring pageview data in localstorage if it's not already there. This is picked up by ophan, see:
// https://github.com/guardian/ophan/blob/75b86abcce07369c8998521399327d436246c016/tracker-js/assets/coffee/ophan/click-path-capture.coffee#L41
// Note - the localstorage item is deleted by tracker-js as soon as it's read, see:
// https://github.com/guardian/ophan/blob/75b86abcce07369c8998521399327d436246c016/tracker-js/assets/coffee/ophan/core.coffee#L72
const setReferrerDataInLocalStorage = (acquisitionData) => {
    const { referrerUrl, referrerPageviewId } = acquisitionData;
    if (!getLocal('ophan_follow') && referrerUrl && referrerPageviewId) {
        setLocal('ophan_follow', JSON.stringify({
            refViewId: referrerPageviewId,
            ref: referrerUrl,
        }));
    }
};
const getPageViewId = () => ophan.viewId;
export { trackComponentEvents, trackAbTests, setReferrerDataInLocalStorage, getPageViewId, };
