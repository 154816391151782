import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { classNameWithModifiers } from 'helpers/utilities/utilities';
import './pageSection.scss';
// ----- Component ----- //
function PageSection(props) {
    return (_jsx("section", { className: classNameWithModifiers('component-page-section', [
            props.modifierClass,
        ]), children: _jsxs("div", { className: "component-page-section__content", children: [_jsxs("div", { className: "component-page-section__header", children: [_jsx(Heading, { heading: props.heading }), props.headingChildren] }), _jsx("div", { className: "component-page-section__body", children: props.children })] }) }));
}
// ----- Auxiliary Components ----- //
function Heading(props) {
    if (props.heading) {
        return _jsx("h2", { className: "component-page-section__heading", children: props.heading });
    }
    return null;
}
// ----- Default Props ----- //
PageSection.defaultProps = {
    modifierClass: '',
    heading: '',
    headingChildren: null,
    children: null,
}; // ----- Exports ----- //
export default PageSection;
