import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { createElement } from 'react';
import SvgArrowRightStraight from 'components/svgs/arrowRightStraight';
import { classNameWithModifiers } from 'helpers/utilities/utilities';
import './button.scss';
// ----- PropTypes ----- //
const Appearances = {
    primary: 'primary',
    secondary: 'secondary',
    tertiary: 'tertiary',
    tertiaryFeature: 'tertiary-feature',
    green: 'green',
    blue: 'blue',
    greenHollow: 'greenHollow',
    greyHollow: 'greyHollow',
    disabled: 'disabled',
};
const Sides = {
    right: 'right',
    left: 'left',
};
// ----- Render ----- //
function SharedButton({ element, appearance, iconSide, modifierClasses, children, icon, getRef, postDeploymentTestID, ...otherProps }) {
    const className = classNameWithModifiers('component-button', [
        appearance,
        `hasicon-${iconSide}`,
        ...modifierClasses,
    ]);
    const contents = [
        _jsx("span", { className: "component-button__content", children: children }),
        icon,
    ];
    return createElement(element, {
        className,
        ref: getRef,
        id: postDeploymentTestID ? `qa-${postDeploymentTestID}` : null,
        ...otherProps,
    }, contents);
}
export const defaultProps = {
    icon: _jsx(SvgArrowRightStraight, {}),
    appearance: Object.keys(Appearances)[0],
    iconSide: Object.keys(Sides)[0],
    modifierClasses: [],
};
SharedButton.defaultProps = { ...defaultProps };
export default SharedButton;
