import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
// ----- Imports ----- //
import Rows from 'components/base/rows';
import AnchorButton from 'components/button/anchorButton';
import Footer from 'components/footerCompliant/Footer';
import Header from 'components/headers/header/header';
import SquaresIntroduction from 'components/introduction/squaresIntroduction';
import Page from 'components/page/page';
import PageSection from 'components/pageSection/pageSection';
import Text, { LargeParagraph } from 'components/text/text';
import '../error.scss';
import { CountryGroup } from 'helpers/internationalisation/classes/countryGroup';
import { contributionsEmail } from 'helpers/legal';
// ----- Component ----- //
export default function ErrorPage(props) {
    return (_jsxs(Page, { header: _jsx(Header, { countryGroupId: CountryGroup.detect() }), footer: _jsx(Footer, {}), children: [_jsx(SquaresIntroduction, { headings: props.headings, highlights: props.errorCode ? [`Error ${props.errorCode}`] : [] }), _jsx(PageSection, { children: _jsxs(Text, { children: [_jsxs(LargeParagraph, { children: [_jsxs("span", { className: "error-copy__text", children: [props.copy, " "] }), _jsx(ReportLink, { show: props.reportLink ?? false })] }), _jsxs(Rows, { children: [props.supportLink && (_jsx(AnchorButton, { "aria-label": "click here to support the Guardian", href: "/", modifierClasses: ['support-the-guardian'], children: "Support the Guardian" })), _jsx("br", {}), _jsx(AnchorButton, { "aria-label": "click here to return to the Guardian home page", href: "https://www.theguardian.com", appearance: "greyHollow", children: "Go to the Guardian home page" })] })] }) })] }));
} // ----- Auxiliary Components ----- //
function ReportLink(props) {
    if (props.show) {
        return (_jsxs("span", { className: "error-copy__text", children: ["please", ' ', _jsx("a", { className: "error-copy__link", href: contributionsEmail.GBPCountries, children: "report it" }), "."] }));
    }
    return null;
}
// ----- Default Props ----- //
ErrorPage.defaultProps = {
    reportLink: false,
    supportLink: true,
    errorCode: null,
};
