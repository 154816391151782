// ----- Imports ----- //
import { getGlobal } from 'helpers/globalsAndSwitches/globals';
import * as cookie from 'helpers/storage/cookie';
import { getSignoutUrl } from 'helpers/urls/externalLinks';
// ----- Functions ----- //
function getUser() {
    if (window.guardian.user && window.guardian.user.email !== '') {
        const { firstName, lastName, email } = window.guardian.user;
        return {
            firstName: typeof firstName === 'string' ? firstName : undefined,
            lastName: typeof lastName === 'string' ? lastName : undefined,
            email: typeof email === 'string' ? email : undefined,
            isSignedIn: true,
        };
    }
    return {
        isSignedIn: false,
    };
}
function isTestUser() {
    const isDefined = (x) => x !== null && x !== undefined;
    const testMode = window.guardian.testMode;
    const testCookie = cookie.get('_test_username');
    return isDefined(testMode) || isDefined(testCookie);
}
function getUserStateField() {
    const user = getGlobal('user');
    return user?.address4;
}
const isPostDeployUser = () => cookie.get('_post_deploy_user') === 'true';
const signOut = () => {
    window.location.href = getSignoutUrl();
};
// ----- Exports ----- //
export { getUser, isTestUser, isPostDeployUser, getUserStateField, signOut };
