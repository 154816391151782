import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import Highlights from 'components/highlights/highlights';
import SvgSquaresHeroDesktop from 'components/svgs/squaresHeroDesktop';
import SvgSquaresHeroMobile from 'components/svgs/squaresHeroMobile';
import SvgSquaresHeroTablet from 'components/svgs/squaresHeroTablet';
import 'components/heading/heading';
// ----- Component ----- //
function SquaresIntroduction(props) {
    return (_jsxs("section", { className: "component-squares-introduction", children: [_jsx(SvgSquaresHeroDesktop, {}), _jsx(SvgSquaresHeroTablet, {}), _jsx(SvgSquaresHeroMobile, {}), _jsxs("div", { className: "component-squares-introduction__content", children: [_jsx(Highlights, { highlights: props.highlights, headingSize: props.highlightsHeadingSize }), _jsx("h1", { className: "component-squares-introduction__heading", children: props.headings.map((heading) => (_jsx("span", { className: "component-squares-introduction__heading-line", children: heading }))) })] })] }));
}
// ----- Default Props ----- //
SquaresIntroduction.defaultProps = {
    highlights: null,
    highlightsHeadingSize: 2,
}; // ----- Exports ----- //
export default SquaresIntroduction;
